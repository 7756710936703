import { AxiosPromise, AxiosResponse } from 'axios';
import api from './api';

export interface AffiliateData {
  id: number;
  professional_id: number;
  name: string;
  email: string;
  cpf: string;
  cell_phone: string;
  is_active: number;
  commission_percentage: number;
  coupon_id: number;
  account_stripe: string;
  dashboard_link_sent: string | null;
  created_at: string;
  updated_at: string;
  coupon: { name: string };
}

export interface ApiResponseAffiliateList {
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
  data: AffiliateData[];
}

export const Affiliate = {
  CreateAffiliate(data: any): Promise<AxiosResponse<any>> {
    return api.post('/affiliate/create', data);
  },

  getAllAffiliate(
    professionalId: number,
    limit: number,
    page?: number,
  ): AxiosPromise<ApiResponseAffiliateList> {
    return api.get(`/affiliate/list/professional/${professionalId}`, {
      params: {
        limit,
        page,
      },
    });
  },

  updateAffiliate(producerId: number, data: any): AxiosPromise<any> {
    return api.put(`/affiliate/${producerId}/update`, data);
  },

  deleteAffiliate(producerId: number): AxiosPromise<void> {
    return api.delete(`/affiliate/${producerId}/delete`);
  },
};

export default Affiliate;
