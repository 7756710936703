import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaSearch, FaPlus } from 'react-icons/fa';

interface Option {
  id: number;
  label: string;
  percentage: number;
}

interface SearchWithSuggestionsProps {
  options: Option[]; // Receber a lista de opções via props
  onSelectOption: (selectedOption: Option) => void; // Callback para enviar a opção selecionada ao pai
}

const AddIcon = styled(FaPlus)`
  margin-right: 8px; /* Espaço entre o ícone e o texto */
  color: #fff; /* Cor do ícone */
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  color: #ccc;
  right: 10px;
  bottom: 20px;
  font-size: 20px;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ccc !important;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0px 20px 20px 20px; /* Remove padding padrão */
  margin: 0; /* Remove margin padrão */
  border: none; /* Remove borda padrão */
  outline: none; /* Remove contorno padrão ao focar */
  font-size: 16px;
  background-color: transparent;

  &:focus {
    box-shadow: none; /* Remove sombra padrão ao focar */
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #575757;
  border-radius: 10px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  width: 90%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SuggestionItem = styled.li`
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc !important;
  &:hover {
    background-color: #727272;
  }
`;

const SearchWithSuggestions: React.FC<SearchWithSuggestionsProps> = ({
  options,
  onSelectOption,
}) => {
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    if (query) {
      const filtered = options.filter(option =>
        option.label.toLowerCase().includes(query.toLowerCase()),
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options); // Mostrar todas as opções se não houver query
    }
  }, [query, options]);

  const handleSelectOption = (option: Option): void => {
    // Aqui, estamos limpando o campo de entrada e não mostrando a opção selecionada
    setQuery(''); // Limpa o campo de entrada
    setShowSuggestions(false); // Esconde as sugestões
    onSelectOption(option); // Passar a opção selecionada ao componente pai
  };

  const handleInputClick = (): void => {
    setShowSuggestions(true);
  };

  const handleInputBlur = (): void => {
    setTimeout(() => setShowSuggestions(false), 100);
  };

  const handleInputFocus = (): void => {
    setShowSuggestions(true);
  };

  return (
    <SearchContainer>
      <SearchIcon />
      <SearchInput
        type="text"
        value={query}
        onChange={e => setQuery(e.target.value)}
        onClick={handleInputClick}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Pesquisar coprodutor"
      />
      {showSuggestions && filteredOptions.length > 0 && (
        <SuggestionsList>
          {filteredOptions.map(option => (
            <SuggestionItem
              key={option.id}
              onClick={
                option.label !== 'Nenhum coprodutor cadastrado'
                  ? () => handleSelectOption(option)
                  : undefined
              }
            >
              {option.label}
              <AddIcon />
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </SearchContainer>
  );
};

export default SearchWithSuggestions;
