import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiLink, FiTrash } from 'react-icons/fi';
import { GoPencil } from 'react-icons/go';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FaRegTrashAlt } from 'react-icons/fa';

import Button from '../Button';
import * as S from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Paginate from '../Paginate';
import { PageOptionsRequest } from '~/models/Common';
import { AffiliateSchema, coproducerSchema } from '~/utils/schemas/coproducer';
import Coproducer, {
  ProducerType,
  ProducerWithMeta,
} from '~/services/Coproducer';
import { useLoading } from '~/hooks/Loading';
import { InputMask } from '..';
import { useToast } from '~/hooks/Toast';
import Affiliate, {
  AffiliateData,
  ApiResponseAffiliateList,
} from '~/services/Affiliate';

interface AssociatesListProps {
  professionalId: number;
}

type CoproducerFormValues = {
  name: string;
  email: string;
  cell_phone: string;
  cpf: string;
};

type AffiliateFormValues = CoproducerFormValues & {
  commissionPercentage: number;
};

export const AssociatesList: React.FC<AssociatesListProps> = ({
  professionalId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { setLoading } = useLoading();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ConfirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [
    confirmDeleteAffiliateIsOpen,
    setConfirmDeleteAffiliateIsOpen,
  ] = useState(false);
  const [pageOptions, setPageOptions] = useState<any>({
    page: 1,
  });
  const [pageOptionsAffiliate, setPageOptionsAffiliate] = useState<any>({
    page: 1,
  });
  const [
    affiliateConfirmModalIsOpen,
    setAffiliateConfirmModalIsOpen,
  ] = useState<boolean>(false);
  const [couponName, setCouponName] = useState<string>('');
  const [newProducer, setNewProducer] = useState<boolean>(false);
  const [producesList, SetProducesList] = useState<ProducerWithMeta | null>(
    null,
  );
  const [
    affiliateList,
    SetAffiliateList,
  ] = useState<ApiResponseAffiliateList | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [producerId, setProducerId] = useState<number>(0);
  const [affiliateIsVisible, setAffiliateIsVisible] = useState(false);
  const [affiliateModalIsOpen, setAffiliateModalIsOpen] = useState(false);
  const [percentageValue, setPercentageValue] = useState(5);
  const [deleteAffiliateModalIsOpen, setDeleteAffiliateModalIsOpen] = useState(
    false,
  );
  const [affiliateId, setAffiliateId] = useState<number>(0);

  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
  } = useForm<CoproducerFormValues>({
    resolver: yupResolver(coproducerSchema),
  });

  const {
    register: registerAffiliate,
    handleSubmit: handleSubmitAffiliate,
    formState: { errors: errorsAffiliate },
    setError: setErrorAffiliate,
    setValue: setValueAffiliate,
    watch: watchAffiliate,
    clearErrors: clearErrorsAffiliate,
  } = useForm<AffiliateFormValues>({
    resolver: yupResolver(AffiliateSchema),
  });

  const handleModalOpen = (): void => {
    setValue('name', '');
    setValue('cpf', '');
    setValue('cell_phone', '');
    setValue('email', '');

    setModalIsOpen(true);
    setEditMode(false);
  };

  const handleAffiliateModalOpen = (): void => {
    setValueAffiliate('name', '');
    setValueAffiliate('cpf', '');
    setValueAffiliate('cell_phone', '');
    setValueAffiliate('email', '');
    setValueAffiliate('commissionPercentage', 5);

    setAffiliateModalIsOpen(true);

    setEditMode(false);
  };

  function validarCPF(inputCpf: string): boolean {
    const cpf = inputCpf.replace(/\D/g, '');

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let soma = 0;
    let resto: number;

    for (let i = 1; i <= 9; i += 1) {
      soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;

    soma = 0;

    for (let i = 1; i <= 10; i += 1) {
      soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;

    return true;
  }

  const handleCoproducer = async (data: any): Promise<void> => {
    const isValidCPF = validarCPF(data.cpf);
    if (!isValidCPF && !editMode) {
      setLoading(false);
      setError('cpf', { type: 'manual', message: 'CPF inválido' });
      return;
    }
    try {
      setLoading(true);
      const updatedData = {
        ...data,
        professionalId,
      };

      let response;
      if (editMode) {
        response = await Coproducer.updateCoproducer(producerId, data);
        setProducerId(0);
      } else {
        response = await Coproducer.CreateCoproducer(updatedData);
      }

      if (!response.data) {
        setLoading(false);
        addToast({
          title: 'CPF ou Email já cadastrado',
          type: 'error',
        });
        return;
      }

      setNewProducer(!newProducer);
      setLoading(false);
      setModalIsOpen(false);
      setConfirmModalIsOpen(true);
    } catch (error: any) {
      setLoading(false);
      console.error('erro capiturado', error.response.data || error.message);

      console.log(error);
    }
  };

  const handleAffiliate = async (data: any): Promise<void> => {
    const isValidCPF = validarCPF(data.cpf);
    if (!isValidCPF && !editMode) {
      setLoading(false);
      setErrorAffiliate('cpf', { type: 'manual', message: 'CPF inválido' });
      return;
    }

    try {
      setLoading(true);

      const updatedData = {
        ...data,
        discountPercentage: 10,
        durationDays: 99999,
      };

      let response;
      if (editMode) {
        response = await Affiliate.updateAffiliate(affiliateId, data);
        setAffiliateId(0);
      } else {
        response = await Affiliate.CreateAffiliate(updatedData);
      }

      if (!response.data) {
        setLoading(false);
        addToast({
          title: 'CPF ou Email já cadastrado',
          type: 'error',
        });
        return;
      }

      setNewProducer(!newProducer);
      setLoading(false);
      setAffiliateModalIsOpen(false);
      setAffiliateConfirmModalIsOpen(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const incrementPercentage = (): void => {
    if (percentageValue + 1 === 81) {
      return;
    }
    setPercentageValue(percentageValue + 1);
  };

  const decrementPercentage = (): void => {
    if (percentageValue - 1 === 4) {
      return;
    }
    setPercentageValue(percentageValue - 1);
  };

  const handlePercentageChange = (value: number): void => {
    const formattedValue = Math.max(0, Math.min(80, value));
    setPercentageValue(formattedValue);
  };

  const handleKeyDown = (value: string): void => {
    if (value.length >= 2) {
      const newValue = value.slice(0, -2);
      setPercentageValue(Number(newValue));
    } else {
      setPercentageValue(0);
    }
  };
  const handleInputChange = (
    field: 'name' | 'email' | 'cpf' | 'cell_phone',
    value: string,
  ): void => {
    setError(field as any, { type: '' });
    setValue(field, value);
  };

  const handleAffiliateInputChange = (
    field: 'name' | 'email' | 'cpf' | 'cell_phone' | 'commissionPercentage',
    value: string,
  ): void => {
    setErrorAffiliate(field as any, { type: '' });
    setValueAffiliate(field, value);
  };

  const getAllCoproducers = async (): Promise<void> => {
    try {
      setLoading(true);
      const limit = 10;
      const { page } = pageOptions;

      const { data } = await Coproducer.getAllProducers(
        professionalId,
        limit,
        page,
      );

      SetProducesList(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleEditCoproducer = (editProducer: ProducerType): void => {
    setEditMode(true);
    setModalIsOpen(true);

    setValue('name', editProducer.name);
    setValue('cpf', editProducer.cpf);
    setValue('cell_phone', editProducer.cell_phone);
    setValue('email', editProducer.email);
    setProducerId(editProducer.id);
  };

  const handleEditAffiliate = (editAfiliate: AffiliateData): void => {
    setEditMode(true);
    setAffiliateModalIsOpen(true);

    setValueAffiliate('name', editAfiliate.name);
    setValueAffiliate('cpf', editAfiliate.cpf);
    setValueAffiliate('cell_phone', editAfiliate.cell_phone);
    setValueAffiliate('email', editAfiliate.email);
    setValueAffiliate(
      'commissionPercentage',
      editAfiliate.commission_percentage,
    );
    setCouponName(editAfiliate.coupon.name);
    setPercentageValue(editAfiliate.commission_percentage);
    setAffiliateId(editAfiliate.id);
  };

  const handleDeleteCoproducer = (id: number): void => {
    setDeleteModalIsOpen(true);
    setProducerId(id);
  };

  const confirmDeleteCoproducer = async (): Promise<void> => {
    try {
      setLoading(true);

      await Coproducer.deleteCoproducer(producerId);

      setConfirmDeleteIsOpen(true);
      setNewProducer(!newProducer);
      setDeleteModalIsOpen(false);
      setLoading(false);
      setProducerId(0);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCoproducers();
  }, [newProducer, professionalId, pageOptions]);

  const CopyCoupon = (text: string): void => {
    const textToCopy = text;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addToast({
        title: 'Cupom copiado para área de transferência!',
        type: 'success',
      });
    });
  };

  const getAllAffiliate = async (): Promise<void> => {
    try {
      setLoading(true);
      const limit = 10;
      const { page } = pageOptionsAffiliate;

      const { data } = await Affiliate.getAllAffiliate(
        professionalId,
        limit,
        page,
      );

      SetAffiliateList(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAffiliate();
  }, [newProducer, professionalId, pageOptionsAffiliate]);

  const handleDeleteAffiliate = (id: number): void => {
    setDeleteAffiliateModalIsOpen(true);
    setAffiliateId(id);
  };

  const confirmDeleteAffiliate = async (): Promise<void> => {
    try {
      setLoading(true);

      await Affiliate.deleteAffiliate(affiliateId);

      setConfirmDeleteAffiliateIsOpen(true);
      setNewProducer(!newProducer);
      setDeleteAffiliateModalIsOpen(false);
      setLoading(false);
      setAffiliateId(0);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const formatCPF = (cpf: string): string => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatPhone = (phone: string): string => {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  useEffect(() => {
    setValueAffiliate('commissionPercentage', percentageValue);
  }, [percentageValue, setValue]);

  return (
    <>
      <S.ListContainer>
        <S.TextContent>
          <h1>COPRODUTORES</h1>
          <p>Chame colaboradores para a sua produção e promoção de cursos</p>
        </S.TextContent>
        <Button
          type="button"
          IsGray
          size="220px"
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? 'MOSTRAR MENOS' : 'MOSTRAR MAIS'}
        </Button>
      </S.ListContainer>

      <S.CoProducersContainer isVisible={isVisible}>
        <S.ButtonContent>
          <Button type="button" onClick={() => handleModalOpen()}>
            NOVO COPRODUTOR
          </Button>
        </S.ButtonContent>

        {!producesList ? (
          <h3>Você ainda não cadastrou nenhum coprodutor</h3>
        ) : (
          <>
            <S.Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>CPF</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {producesList.data.map(producer => (
                  <tr key={producer.id}>
                    <td>{producer.name}</td>
                    <td>{producer.email}</td>
                    <td>
                      {producer.cell_phone
                        ? formatPhone(producer.cell_phone)
                        : ''}
                    </td>
                    <td>{producer.cpf ? formatCPF(producer.cpf) : ''}</td>
                    <td>
                      <S.ActionButton
                        type="button"
                        onClick={() => handleEditCoproducer(producer)}
                      >
                        <GoPencil />
                      </S.ActionButton>
                      <S.ActionButton
                        onClick={() => handleDeleteCoproducer(producer.id)}
                        type="button"
                      >
                        <FiTrash />
                      </S.ActionButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </S.Table>
            <Paginate
              {...{ setPageOptions }}
              pageCount={producesList.lastPage}
              pageRangeDisplayed={0}
              noScroll
            />
          </>
        )}
      </S.CoProducersContainer>

      <S.ListContainer>
        <S.TextContent>
          <h1>AFILIADOS</h1>
          <p>
            Tenha afiliados para promover seus cursos através do link exclusivo
            dele. O afiliado irá ganhar uma porcentagem de cada venda.
          </p>
        </S.TextContent>
        <Button
          type="button"
          IsGray
          size="220px"
          onClick={() => setAffiliateIsVisible(!affiliateIsVisible)}
        >
          {affiliateIsVisible ? 'MOSTRAR MENOS' : 'MOSTRAR MAIS'}
        </Button>
      </S.ListContainer>

      <S.CoProducersContainer isVisible={affiliateIsVisible}>
        <S.ButtonContent>
          <Button type="button" onClick={() => handleAffiliateModalOpen()}>
            NOVO AFILIADO
          </Button>
        </S.ButtonContent>

        {!affiliateList ? (
          <h3>Você ainda não cadastrou nenhum afiliado</h3>
        ) : (
          <>
            <S.Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>CPF</th>
                  <th>Percentual</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {affiliateList.data.map(affiliate => (
                  <tr key={affiliate.id}>
                    <td>{affiliate.name}</td>
                    <td>{affiliate.email}</td>
                    <td>
                      {affiliate.cell_phone
                        ? formatPhone(affiliate.cell_phone)
                        : ''}
                    </td>
                    <td>{affiliate.cpf ? formatCPF(affiliate.cpf) : ''}</td>
                    <td style={{ paddingLeft: '40px' }}>
                      {affiliate.commission_percentage} %
                    </td>
                    <td>
                      <S.ActionButton
                        type="button"
                        onClick={() => CopyCoupon(affiliate.coupon.name)}
                      >
                        <FiLink />
                      </S.ActionButton>
                      <S.ActionButton
                        type="button"
                        onClick={() => handleEditAffiliate(affiliate)}
                      >
                        <GoPencil />
                      </S.ActionButton>
                      <S.ActionButton
                        onClick={() => handleDeleteAffiliate(affiliate.id)}
                        type="button"
                      >
                        <FiTrash />
                      </S.ActionButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </S.Table>
            <Paginate
              {...{ setPageOptions: setPageOptionsAffiliate }}
              pageCount={affiliateList.lastPage}
              pageRangeDisplayed={0}
              noScroll
            />
          </>
        )}
      </S.CoProducersContainer>

      {/* modal de criar coprodutor */}
      <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
        <h1>{editMode ? 'Editar coprodutor' : 'Novo coprodutor'}</h1>
        <S.ModalForm id="Coproducer" onSubmit={e => e.preventDefault()}>
          <S.Label>
            Nome:
            <Input
              value={watch('name')}
              name="Coname"
              onChange={e => handleInputChange('name', e.target.value)}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </S.Label>

          <S.Label>
            E-mail:
            <Input
              value={watch('email')}
              name="Coemail"
              onChange={e => handleInputChange('email', e.target.value)}
              disabled={editMode}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </S.Label>

          <S.Label>
            Telefone:
            <InputMask
              value={watch('cell_phone')}
              mask="(99) 99999-9999"
              name="Cophone"
              onChange={e => handleInputChange('cell_phone', e.target.value)}
            />
            {errors.cell_phone && <p>{errors.cell_phone.message}</p>}
          </S.Label>

          <S.Label>
            CPF:
            <InputMask
              value={watch('cpf')}
              mask="999.999.999-99"
              name="Cocpf"
              onChange={e => {
                const cpfValue = e.target.value;
                handleInputChange('cpf', cpfValue);

                clearErrors('cpf');
              }}
              disabled={editMode}
            />
            {errors.cpf && <p>{errors.cpf.message}</p>}
          </S.Label>

          <S.ModalButtonContent>
            <Button
              type="button"
              style={{ marginTop: '30px' }}
              onClick={handleSubmit(data => handleCoproducer(data))}
            >
              {editMode ? 'SALVAR ALTERAÇÕES' : 'CADASTRAR COPRODUTOR'}
            </Button>
          </S.ModalButtonContent>
        </S.ModalForm>
      </Modal>

      {/* confirmar de exclução */}
      <Modal
        modalIsOpen={deleteModalIsOpen}
        setModalIsOpen={setDeleteModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegTrashAlt
            style={{
              borderRadius: '50%',
              border: '3px solid white',
              padding: '30px',
            }}
          />

          <h1>Realmente deseja excluir este coprodutor?</h1>
          <h2>
            Caso exclua, o coprodutor será desvinculado e as porcentagens de
            participação vinculadas a ele serão somadas às suas.
          </h2>
          <S.ModalButtonContent style={{ gap: '40px' }}>
            {' '}
            <Button
              type="button"
              onClick={() => setDeleteModalIsOpen(false)}
              style={{ marginTop: '30px', backgroundColor: '#575757' }}
            >
              CANCELAR
            </Button>
            <Button
              type="button"
              onClick={() => confirmDeleteCoproducer()}
              style={{ marginTop: '30px' }}
            >
              EXCLUIR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>

      {/* confirmação de exclusão */}

      <Modal
        modalIsOpen={confirmDeleteIsOpen}
        setModalIsOpen={setDeleteModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegCheckCircle />

          <h1>Coprodutor excluído com sucesso!</h1>

          <S.ModalButtonContent style={{ gap: '40px' }}>
            <Button
              type="button"
              onClick={() => setConfirmDeleteIsOpen(false)}
              style={{ marginTop: '30px' }}
            >
              FINALIZAR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>

      <Modal
        modalIsOpen={ConfirmModalIsOpen}
        setModalIsOpen={setConfirmModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegCheckCircle />

          <h1>
            {editMode
              ? 'Informações de coprodutor alteradas com sucesso!'
              : 'Coprodutor cadastrado com sucesso!'}
          </h1>
          <h2>
            {!editMode &&
              'Um e-mail foi enviado para o coprodutor. Para poder receber a participação em cada curso, é necessário completar o cadastro presente no e-mail.'}
          </h2>
          <S.ModalButtonContent>
            {' '}
            <Button
              type="button"
              onClick={() => setConfirmModalIsOpen(false)}
              style={{ marginTop: '30px' }}
            >
              FINALIZAR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>

      {/* modal de criar Afiliado */}
      <Modal
        modalIsOpen={affiliateModalIsOpen}
        setModalIsOpen={setAffiliateModalIsOpen}
      >
        <h1>{editMode ? 'Editar afiliado' : 'Novo afiliado'}</h1>
        <S.ModalForm id="Affiliate" onSubmit={e => e.preventDefault()}>
          <S.Label>
            Nome:
            <Input
              value={watchAffiliate('name')}
              name="Aname"
              onChange={e => handleAffiliateInputChange('name', e.target.value)}
            />
            {errorsAffiliate.name && <p>{errorsAffiliate.name.message}</p>}
          </S.Label>

          <S.Label>
            E-mail:
            <Input
              value={watchAffiliate('email')}
              name="Aemail"
              disabled={editMode}
              onChange={e =>
                handleAffiliateInputChange('email', e.target.value)
              }
            />
            {errorsAffiliate.email && <p>{errorsAffiliate.email.message}</p>}
          </S.Label>

          <S.Label>
            Telefone:
            <InputMask
              value={watchAffiliate('cell_phone')}
              mask="(99) 99999-9999"
              name="Aphone"
              onChange={e =>
                handleAffiliateInputChange('cell_phone', e.target.value)
              }
            />
            {errorsAffiliate.cell_phone && (
              <p>{errorsAffiliate.cell_phone.message}</p>
            )}
          </S.Label>

          <S.Label>
            CPF:
            <InputMask
              value={watchAffiliate('cpf')}
              mask="999.999.999-99"
              name="Acpf"
              disabled={editMode}
              onChange={e => handleAffiliateInputChange('cpf', e.target.value)}
            />
            {errorsAffiliate.cpf && <p>{errorsAffiliate.cpf.message}</p>}
          </S.Label>

          <S.Label style={{ gap: '10px' }}>
            Percentual de venda:
            <S.PercentageContent>
              <S.Circle
                color="#b98e4f"
                onClick={() => !editMode && decrementPercentage()}
                style={{ paddingBottom: '8px' }}
                isEdit={editMode}
              >
                -
              </S.Circle>

              <input
                disabled={editMode}
                maxLength={4}
                type="text"
                value={`${percentageValue}%`}
                onChange={e => {
                  const value = e.target.value.replace('%', '');
                  const numericValue = Number(value);
                  if (!Number.isNaN(numericValue) && value !== '') {
                    handlePercentageChange(numericValue);
                  }
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    e.preventDefault();
                    handleKeyDown(e.currentTarget.value);
                  }
                }}
              />

              <S.Circle
                isEdit={editMode}
                color="#606060"
                onClick={() => !editMode && incrementPercentage()}
              >
                +
              </S.Circle>
            </S.PercentageContent>
            {errorsAffiliate.commissionPercentage && (
              <p>{errorsAffiliate.commissionPercentage.message}</p>
            )}
            <S.ComplementaryText>
              Determine o percentual de ganho por cada venda desse afiliado. O
              valor deve ser no mínimo 5% no máximo 80%.
            </S.ComplementaryText>
          </S.Label>

          {/* apenas na parte de editar */}
          {editMode && (
            <S.Label>
              Link de afiliado:
              <Input
                name="link"
                icon
                disabled
                value={couponName}
                linkValue={couponName}
              />
              <S.ComplementaryText>
                Este link é único para este afiliado
              </S.ComplementaryText>
            </S.Label>
          )}

          <S.ModalButtonContent>
            <Button
              type="button"
              style={{ marginTop: '30px' }}
              onClick={handleSubmitAffiliate(data => handleAffiliate(data))}
            >
              {editMode ? 'SALVAR ALTERAÇÕES' : 'CADASTRAR AFILIADO'}
            </Button>
          </S.ModalButtonContent>
        </S.ModalForm>
      </Modal>

      {/* excluir afiliado */}

      <Modal
        modalIsOpen={deleteAffiliateModalIsOpen}
        setModalIsOpen={setDeleteAffiliateModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegTrashAlt
            style={{
              borderRadius: '50%',
              border: '3px solid white',
              padding: '30px',
            }}
          />

          <h1>Realmente deseja excluir este afiliado?</h1>
          <h2>Após excluir, não poderá reverter essa ação</h2>
          <S.ModalButtonContent style={{ gap: '40px' }}>
            {' '}
            <Button
              type="button"
              onClick={() => setDeleteAffiliateModalIsOpen(false)}
              style={{ marginTop: '30px', backgroundColor: '#575757' }}
            >
              CANCELAR
            </Button>
            <Button
              type="button"
              onClick={() => confirmDeleteAffiliate()}
              style={{ marginTop: '30px' }}
            >
              EXCLUIR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>

      {/* confirmação de exclusão */}

      <Modal
        modalIsOpen={confirmDeleteAffiliateIsOpen}
        setModalIsOpen={setConfirmDeleteAffiliateIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegCheckCircle />

          <h1>Afiliado excluído com sucesso!</h1>

          <S.ModalButtonContent style={{ gap: '40px' }}>
            <Button
              type="button"
              onClick={() => setConfirmDeleteAffiliateIsOpen(false)}
              style={{ marginTop: '30px' }}
            >
              FINALIZAR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>

      <Modal
        modalIsOpen={affiliateConfirmModalIsOpen}
        setModalIsOpen={setAffiliateConfirmModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegCheckCircle />

          <h1>
            {editMode
              ? 'Informações do afiliado alteradas com sucesso!'
              : 'Filiado cadastrado com sucesso!'}
          </h1>

          <S.ModalButtonContent>
            <Button
              type="button"
              onClick={() => setAffiliateConfirmModalIsOpen(false)}
              style={{ marginTop: '30px' }}
            >
              FINALIZAR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>
    </>
  );
};
