import * as yup from 'yup';

export const coproducerSchema = yup.object({
  name: yup.string().required('Nome é obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  cell_phone: yup
    .string()
    .required('Telefone é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^\d{11}$/, 'Telefone deve ter 11 dígitos (incluindo o DDD)'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^\d{11}$/, 'CPF deve ter 11 dígitos'),
});

export const AffiliateSchema = yup.object({
  name: yup.string().required('Nome é obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  cell_phone: yup
    .string()
    .required('Telefone é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^\d{11}$/, 'Telefone deve ter 11 dígitos (incluindo o DDD)'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^\d{11}$/, 'CPF deve ter 11 dígitos'),
  commissionPercentage: yup
    .number()
    .typeError('Percentual de comissão deve ser um número')
    .required('Percentual de comissão é obrigatório')
    .min(5, 'Percentual de comissão não pode ser menor que 5%')
    .max(80, 'Percentual de comissão não pode ser maior que 80%'),
});
