/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from 'embla-carousel-react';

import { ImageHome, ImageLives } from '~/assets/images';
import { Workouts } from '~/models/Workouts';
import ViewWorkout from '~/pages/Student/Workouts/ViewWorkout';

import * as S from './styles';

import { DotButton } from './Dot';

type PropType = {
  banners?: any;
  slides?: number[];
  images?: string[];
  options?: EmblaOptionsType;
  showSlideContent?: boolean;
};

const EmblaCarousel: React.FC<PropType> = props => {
  const {
    banners,
    slides,
    options,
    images,
    children,
    showSlideContent,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedWorkout, setSelectedWorkout] = useState<Workouts>(
    {} as Workouts,
  );
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ delay: 6000 }),
  ]);
  const [emblaRef2, emblaApi2] = useEmblaCarousel(options, [
    Autoplay({ delay: 6000 }),
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const imageByIndex = (index: number): string =>
    imagesUrls[index % imagesUrls?.length];

  const scrollTo = useCallback(
    (index: number) => {
      emblaApi2 && emblaApi2.scrollTo(index);
      emblaApi && emblaApi.scrollTo(index);
    },
    [emblaApi, emblaApi2],
  );
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const handleSelectWorkout = (data: any): void => {
    const formattedData: Workouts = {
      category: {
        id: 0,
        description: '',
        profession_id: 0,
      },
      image: data?.url ? ImageLives : data.image,
      title: data.title,
      description: data.description,
      url: data?.url,
      path: data?.path,
      is_converted: data?.is_converted,
    };

    setSelectedWorkout(formattedData);

    setModalIsOpen(true);
  };

  const onInit = useCallback((api: EmblaCarouselType) => {
    setScrollSnaps(api.scrollSnapList());
  }, []);

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setSelectedIndex(api.selectedScrollSnap());
    setPrevBtnDisabled(!api.canScrollPrev());
    setNextBtnDisabled(!api.canScrollNext());
  }, []);

  useEffect(() => {
    if ((banners && banners.length > 0) || images) {
      const urls = banners?.map((banner: any) => banner.image) || images;
      const filledUrls = urls?.map((url: string) =>
        url?.length > 0 ? url : ImageHome,
      );
      setImagesUrls(filledUrls);
    } else {
      setImagesUrls([ImageHome]);
    }
  }, [banners, images]);

  useEffect(() => {
    if (emblaApi && emblaApi2) {
      emblaApi2.on('scroll', () => {
        emblaApi.scrollTo(emblaApi2?.selectedScrollSnap());
      });

      emblaApi.on('scroll', () => {
        emblaApi2.scrollTo(emblaApi?.selectedScrollSnap());
      });
    }
  }, [emblaApi, emblaApi2]);

  useEffect(() => {
    if (!emblaApi || !emblaApi2) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    onInit(emblaApi2);
    onSelect(emblaApi2);

    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);

    emblaApi2.on('reInit', onInit);
    emblaApi2.on('select', onSelect);
  }, [emblaApi, emblaApi2, onInit, onSelect]);

  return (
    <>
      <S.Embla>
        {showSlideContent ? (
          <S.Content2>
            <S.Viewport2 ref={emblaRef2}>
              <S.Container2>
                {banners?.length > 0 ? (
                  banners.map((banner: any) => {
                    const { title, description, path } = banner;

                    return (
                      <S.Slide2 key={banner.title}>
                        <S.HeroContent>
                          <S.HeroTitle>{title}</S.HeroTitle>
                          <S.HeroText>{description}</S.HeroText>
                          <S.NavigateContainer>
                            <S.NavigateToLesson
                              isOutline={false}
                              onClick={() => handleSelectWorkout(banner)}
                            >
                              Assista a {path ? 'aula' : 'live'}
                            </S.NavigateToLesson>
                          </S.NavigateContainer>
                        </S.HeroContent>
                      </S.Slide2>
                    );
                  })
                ) : (
                  <S.Slide2>
                    <S.HeroContent>
                      <S.HeroTitle>
                        Realize o pagamento para ter acesso novamente
                      </S.HeroTitle>
                      <S.HeroText>Aulas não disponíveis no momento.</S.HeroText>
                    </S.HeroContent>
                  </S.Slide2>
                )}
              </S.Container2>
            </S.Viewport2>
          </S.Content2>
        ) : (
          <S.Content>{children}</S.Content>
        )}

        <S.Viewport ref={emblaRef}>
          <S.Container>
            {imagesUrls?.map((_, index) => (
              <>
                <S.Slide key={index}>
                  <S.SlideImage src={imageByIndex(index)} alt="Your alt text" />
                </S.Slide>
              </>
            ))}
          </S.Container>
        </S.Viewport>
      </S.Embla>

      <S.Dots>
        {scrollSnaps?.map((_, index) => (
          <DotButton
            key={`${index}_dot`}
            onClick={() => scrollTo(index)}
            className={'embla__dot'.concat(
              index === selectedIndex ? ' embla__dot--selected' : '',
            )}
          />
        ))}
      </S.Dots>

      <ViewWorkout
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        workout={selectedWorkout}
      />
    </>
  );
};

export default EmblaCarousel;
