import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';

import { HeaderMySite, Lives as LivesMemo, Paginate } from '~/components';
import { useProfessor } from '~/hooks/professor';
import { useToast } from '~/hooks/Toast';
import {
  MessageServer,
  PageOptionsRequest,
  PageOptionsResponse,
} from '~/models/Common';
import { Lives as ILives } from '~/models/Lives';
import LivesService from '~/services/LivesService';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';

import * as L from './styles';

import ViewWorkout from './ViewLive';

interface routeParams {
  publicname: string;
}

const Lives: React.FC = () => {
  const [lives, setLives] = useState<PageOptionsResponse<ILives>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  const [selectedLive, setSelectedLive] = useState<ILives>();
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  const { publicname } = useParams<routeParams>();
  const { professor, addProfessor } = useProfessor();
  const { addToast } = useToast();

  const getProfessional = useCallback(async () => {
    try {
      const response = await ProfessorService.getProfessionalByPublicname(
        publicname,
        'lives',
      );
      addProfessor(response.data);
    } catch (err) {
      const errToToast = errorHandlerToToast({
        error: err as Error | AxiosError<MessageServer>,
      });
      if (errToToast) addToast(errToToast);
    }
  }, [addProfessor, addToast, publicname]);

  const getLives = useCallback(
    async (publicName: string): Promise<void> => {
      const response = await LivesService.getLives(
        publicName,
        false,
        pageOptions,
      );

      setLives(response.data);
    },
    [pageOptions],
  );

  useEffect(() => {
    if (!professor?.public_name) {
      getProfessional();
    }
    if (professor?.public_name) {
      getLives(professor.public_name);
    }
  }, [addProfessor, getLives, getProfessional, professor, publicname]);

  const handleOpenModal = useCallback(
    live => {
      setModalIsOpen(!modalIsOpen);
      setSelectedLive(live);
    },
    [modalIsOpen],
  );

  return professor ? (
    <>
      <HeaderMySite professional={professor} />
      <L.LivesContainer>
        <L.LivesTitle>
          <FiChevronLeft
            onClick={() => history.push(`/${professor.public_name}`)}
          />
          Aulas
        </L.LivesTitle>
        <LivesMemo
          lives={lives.data}
          handleOpenLiveModal={handleOpenModal}
          publicname={professor.public_name}
          // professor={professor}
        />
        <Paginate
          pageRangeDisplayed={pageOptions?.page ?? 1}
          pageCount={lives.lastPage}
          {...{ setPageOptions }}
        />
      </L.LivesContainer>
      {selectedLive && (
        <ViewWorkout
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          live={selectedLive}
          publicname={publicname}
        />
      )}
    </>
  ) : null;
};

export default Lives;
