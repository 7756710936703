import { AxiosError, AxiosPromise } from 'axios';

import {
  DatabaseFields,
  MessageServer,
  PageOptionsRequest,
  PhotoFields,
  PageOptionsResponse,
  UpdateProfessionalResponse as Professional,
  Professions,
  Professionscategories,
} from '~/models/Common';
import Courses, { CourseDetails } from '~/models/Courses';
import Interaction from '~/models/Interaction';
import Practice, { NewPraticeRequest } from '~/models/Practice';
import { IProfessionalPlansAdhesion } from '~/models/ProfessionalPlansAdhesion';
import { Workouts, WorkoutsData } from '~/models/Workouts';

import api, { getToken } from './api';

const PER_PAGE_DEFAULT = process.env.REACT_APP_PER_PAGE_DEFAULT ?? 12;

interface VideoUploadCommonFields {
  title: string;
  description: string;
  treining_category_id: string;
  path: string;
}

interface VideoUploadDetails extends DatabaseFields, VideoUploadCommonFields {
  key: string;
  professional_id: number;
  name: string;
}

interface VideoUploadResponse extends MessageServer {
  video: VideoUploadDetails;
}

interface NewCourseResponse extends DatabaseFields, PhotoFields {
  professional_id: number;
  title: string;
  description: string;
  price: string;
  status: string;
}

interface UploadLoadRequest {
  data: FormData;
  token?: string;
}

interface UploadLoadResponse extends MessageServer {
  avatar?: string;
}

interface RemoveVideoRequest {
  idProfessional: number;
  idVideo: number;
}

interface GetPracticeRequest {
  courseId: number;
}
interface DeleteInteractionRequest {
  courseId: number;
  interactionId: number;
}

interface EditPraticeRequest extends NewPraticeRequest {
  praticeId: number;
  setPercent?: (percent: number) => void;
}

interface RemovePraticeRequest {
  courseId: number;
  praticeId: number;
}

interface ValidationUniqueFields {
  email: string;
  cpf: string;
  cnpj: string;
}

type ValidationRequest = Partial<ValidationUniqueFields>;

interface ValidationField {
  field: keyof ValidationUniqueFields;
  validation: 'integer' | 'max' | 'min' | 'unique' | 'email';
}
type ValidationMessage = MessageServer & ValidationField;

export default {
  createPratice({
    data,
    courseId,
    setPercent,
  }: NewPraticeRequest): AxiosPromise<MessageServer> {
    let config = {};
    if (setPercent) {
      config = {
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const percent = (progressEvent.loaded / progressEvent.total) * 100;
          setPercent(percent);
          if (percent > 100) {
            setPercent(0);
          }
        },
        headers: {
          'Context-Type': 'multipart/form-data',
        },
      };
    }

    return api.post(`courses/${courseId}/practices`, data, config);
  },

  createCourse(
    coproducer: any,
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise<NewCourseResponse> {
    if (coproducer) {
      data.append('coproducers', JSON.stringify(coproducer));
    }

    let config = {};
    if (setPercent) {
      config = {
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const percent = (progressEvent.loaded / progressEvent.total) * 100;
          setPercent(percent);
          if (percent > 100) {
            setPercent(0);
          }
        },
      };
    }
    return api.post(`professionals/`, data, config);
  },

  updateCourse(
    data: FormData,
    idCourse: number,
    setPercent: (percent: number) => void,
  ): AxiosPromise<NewCourseResponse> {
    console.log('aqui nao');
    let config = {};
    if (setPercent) {
      config = {
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const percent = (progressEvent.loaded / progressEvent.total) * 100;
          setPercent(percent);
          if (percent > 100) {
            setPercent(0);
          }
        },
      };
    }
    return api.put(`professionals/curso/${idCourse}`, data, config);
  },

  deletePratice({
    courseId,
    praticeId,
  }: RemovePraticeRequest): AxiosPromise<void> {
    return api.delete(`courses/${courseId}/practices/${praticeId}`);
  },

  updatePratice({
    courseId,
    praticeId,
    data,
    setPercent,
  }: EditPraticeRequest): AxiosPromise<void> {
    let config = {};
    if (setPercent) {
      config = {
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const percent = (progressEvent.loaded / progressEvent.total) * 100;
          setPercent(percent);
          if (percent > 100) {
            setPercent(0);
          }
        },
        headers: {
          'Context-Type': 'multipart/form-data',
        },
      };
    }
    return api.put(`courses/${courseId}/practices/${praticeId}`, data, config);
  },

  deleteVideo({ idProfessional, idVideo }: RemoveVideoRequest): AxiosPromise {
    return api.delete(`professionals/${idProfessional}/videos/${idVideo}`);
  },

  videoUploadTraining(
    professionalId: number,
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise<VideoUploadResponse> {
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        setPercent(percent);
        if (percent > 100) {
          setPercent(0);
        }
      },
      headers: {
        'Context-Type': 'multipart/form-data',
      },
    };
    return api.post(`professionals/${professionalId}/videos`, data, config);
  },

  videoUploadEditTraining(
    where: { professionalId: number; videoId: number },
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise<VideoUploadResponse> {
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        setPercent(percent);
        if (percent > 100) {
          setPercent(0);
        }
      },
      headers: {
        'Context-Type': 'multipart/form-data',
      },
    };
    return api.put(
      `professionals/${where.professionalId}/videos/${where.videoId}`,
      data,
      config,
    );
  },

  videoUploadCourse(
    courseId: number,
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise<VideoUploadResponse> {
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        setPercent(percent);
        if (percent > 100) {
          setPercent(0);
        }
      },
      headers: {
        'Context-Type': 'multipart/form-data',
      },
    };
    return api.post(`courses/${courseId}/videos`, data, config);
  },

  videoUploadEditCourse(
    where: { videoId: number; courseId: number },
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise<VideoUploadResponse> {
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        setPercent(percent);
        if (percent > 100) {
          setPercent(0);
        }
      },
      headers: {
        'Context-Type': 'multipart/form-data',
      },
    };
    return api.put(
      `courses/${where.courseId}/videos/${where.videoId}`,
      data,
      config,
    );
  },

  updateLogo({
    data,
    token,
  }: UploadLoadRequest): AxiosPromise<UploadLoadResponse> {
    return api.put('professionals/alter/logo', data, {
      headers: {
        Authorization: token
          ? `Bearer ${token}`
          : api.defaults.headers.Authorization,
      },
    });
  },

  getPractice({ courseId }: GetPracticeRequest): AxiosPromise<Practice[]> {
    return api.get(`courses/${courseId}/practices`, {
      cancelToken: getToken().token,
    });
  },

  getInteractions({
    courseId,
  }: GetPracticeRequest): AxiosPromise<Interaction[]> {
    return api.get(`courses/${courseId}/lives`, {
      cancelToken: getToken().token,
    });
  },
  deleteInteractions({
    courseId,
    interactionId,
  }: DeleteInteractionRequest): AxiosPromise<void> {
    return api.delete(`courses/${courseId}/lives/${interactionId}`);
  },

  getCourses(
    public_name: string,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<PageOptionsResponse<Courses>> {
    return api.get(`professionals/${public_name}/courses`, {
      params: {
        page,
        perpage,
      },
    });
  },

  getVideosByCourse(
    videoId: number,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<PageOptionsResponse<CourseDetails>> {
    return api.get(`courses/${videoId}/videos`, {
      params: {
        page,
        perpage,
      },
      cancelToken: getToken().token,
    });
  },

  getWorkoutsByProfessional(
    professionalId: number,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
    category?: number,
  ): AxiosPromise<WorkoutsData> {
    return api.get(`professionals/${professionalId}/videos`, {
      params: {
        category,
        page,
        perpage,
      },
    });
  },

  getProfessionalByPublicname(
    publicname: string,
    path: string,
  ): AxiosPromise<Professional> {
    return api.get(`/professionals/${publicname}/publicname`, {
      cancelToken: getToken().token,
    });
  },

  getProfessionalById(professionalId: number): AxiosPromise<Professional> {
    return api.get(`/professionals/${professionalId}`, {
      cancelToken: getToken().token,
    });
  },

  getProfessionalWorkouts(
    id: number,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<PageOptionsResponse<Workouts>> {
    return api.get(`/professionals/${id}/trainings`, {
      params: {
        page,
        perpage,
      },
      cancelToken: getToken().token,
    });
  },

  getallProfessionalPlansAdhesion(): AxiosPromise<
    IProfessionalPlansAdhesion[]
  > {
    return api.get('/admins/plans/professional/Adhesion/all');
  },

  professionalsPlanSign(data: { planId: number }): AxiosPromise {
    return api.post(`/plan/sign/${data.planId}`);
  },

  checkAccountStripe(data: { userId: number }): AxiosPromise {
    return api.get(`/professionals/checkAccountStripe/${data.userId}`);
  },

  getprofessionalSignedPlans(): AxiosPromise {
    return api.get(`/professional/getprofessionalSignedPlans`);
  },

  cancelProfessionalSignedPlan(data: { professionalId: number }): AxiosPromise {
    return api.post(`/professional/subscription/cancel/${data.professionalId}`);
  },

  validate(
    data: ValidationRequest,
  ): AxiosPromise<MessageServer> | AxiosError<ValidationMessage[]> {
    return api.post('/professional/validate', data);
  },

  getProfessions(): AxiosPromise<Professions[]> {
    return api.get(`/professions/index`);
  },

  getProfessionCategories(): AxiosPromise<Professionscategories> {
    return api.get(`/professional/profession/categories`);
  },
};
